@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@600&family=Work+Sans:wght@300&display=swap");

* {
  box-sizing: border-box;
}

iframe {
  pointer-events: none;
}

html {
  background-color: rgba(20, 20, 25, 1);
}

body {
  margin:0;
  padding: 0;
  font-family: "Work Sans", sans-serif;
  font-weight: 300;
  font-size: 24px;
  line-height: 1.2180;

  color: rgb(216, 220, 235);

  @media (max-width: 900px){
    font-size: 20px;
  }

  @media (max-width: 400px){
    font-size: 15px;
  }

}

button, input, textarea {
  font-family: "Space Grotesk", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Space Grotesk", sans-serif;
  font-weight: 300;
  line-height: 1em;
  margin: 0.6em 0 1em 0;
  color: #d8dceb;

  a {
    color: #d8dceb;
  }
}

p {
  line-height: 1.4em;
}

h2 a {
  font-weight: 100;
}

p:first-child {
  margin-top: 0;
}

p {
  margin-top: 1.218em;
}

header {
  position: relative;
  color: rgb(216, 220, 231);
  padding: 0; margin: 0;

}

details {
  margin-bottom: 3em;
}

header h1 {
  margin-bottom: 0.05em;
}

header h2 {
  font-size: 0.5em;
}

nav a {
  position: relative;
  font-size: 1em;

  @media (max-width: 900px) {
    font-size: .8em;
  }

  @media (max-width: 600px) {
    font-size: 0.6em;
  }

}

.types {
  color: white;
  font-size: 0.6em;
}

.all-work {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 20px;
  grid-template-rows: masonry;
  div {
    justify-self: stretch;
  }

}

.App {
  font-family: sans-serif;
  text-align: left;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

li {
  margin-bottom: 1em;
}

a,
button {
  font-size: 1em;
  color: #d8dceb;
  text-decoration: none;
  transition: 0.2s;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  display: inline-block;

      &:hover {
        color: #ffffff;
      transition: 0.2s;
    }
  }



.info-box {
  background-color: black;
  padding: 0.5em;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  h2 {
    font-size: 0.5em;
    margin: 0;
  }

  h3 {
    font-size: 0.5em;
    margin: 0;
  }

}

input[type="checkbox"] {
  text-align: left;
  font-size: 2em;
  width: 18px;
  scale: 2;
  margin-right: 0.3em;
}


.hide {
  opacity: 0;
  transition-delay: 0.5ms;
  transition: opacity 0.2s;
}

.show {
  opacity: 1;
  transition-delay: 0.5ms;
  transition: opacity 0.5s;
}

form button,
.save-button {
  font-size: 1em;
  background-color: #2bf6ec;
  color: #25282F;
  border-radius: 6px;
  padding: 8px 10px;
}

.filter-drawer {
  display: grid;
  grid-template-columns: 1em 1fr;
  gap: 1em;
  margin-bottom: 4em;
  place-content: top;
}

.toggle-button {
  font-size: 2em;
  align-self: flex-start;
  place-content: flex-start;
  padding: 0;
  margin: 0;
  line-height: 0;
}

.toggle-button:hover {
  transform: translateY(0);
}

.mast {
  display: grid;
  grid-template-columns: 1fr 200px;
  gap: 2em;
}

nav a {
  margin: 0.2em 0.5em;
  display: inline-block;
  transition: 0.2s;
  margin-bottom: 4px;
  &.active {
    transition: 0.2s;
    color: #2bf6ec;
    border-bottom: 4px solid #2bf6ec;
    margin-bottom: 0px;
  }
}

.item, .year {
  height: 200px;
}

.year {
  justify-content: center;
  align-self: center;
}

.portfolio {
  
  padding: 0 5vw;
  columns: 4 auto;
  column-gap: 10px;
  column-fill: balance;
  row-gap: 10px;

  a {
    display: block;
  }

  @media (max-width: 900px) {
    columns: 2 auto;
  }

  @media (max-width: 600px) {
    columns: 1 auto;
  }

}

.sub-portfolio {
  columns: 2 auto;
  column-gap: 10px;
  column-fill: balance;
  // row-gap: 10px;
  a {
    display: block;
  }
  @media (max-width: 900px) {
    columns: 1 auto;
  }

  &.four-column {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

}

.header-canvas {
   width: 100vw;
   height: 50vw;
   position: fixed;
   z-index: 1;
   pointer-events: none; 
   mix-blend-mode: plus-lighter;
    opacity: 1;
    transition: opacity 0.5s;

   &.hide {
    opacity: 0;
    transition: opacity 0.5s;
   }


}

.page {
  padding: 0 5vw;
  columns: 1 auto;
  column-gap: 50px;
  row-gap: 10px;

  a {
    color: rgb(42, 196, 119);

    &:hover {
      color: rgb(100,255,177);
    }

    &:visited {
      color: rgb(188, 100, 255);
    }

  }

}

.max-page {
  padding: 1vw;
}

.gap {
  gap: 5vw;
}


.statement, .linktree {
  padding: 0 25vw;
  columns: 1 auto;
  column-gap: 50px;
  row-gap: 10px;

  @media (max-width: 900px) {
    padding: 0 5vw;
  }

  @media (max-width: 600px) {
    padding: 0 5vw;
  }

}

.media-item {
  position: relative;
  width: 100%;
  display: block;
  &:hover {
    cursor: pointer;
    .info-box {
      opacity: 1;
      transition: opacity 0.2s;
    }
  }
}

.media-item-video,
.media-item-image {

  img, video {
    width: 100%;
    // height: auto;
    aspect-ratio: var(--ar);
    
  }

video {
    width: 100%;
    max-height: 90vh;
  }

}

.info-box {
  position: absolute;
  top: 0;
  opacity: 0;
  transition: opacity 0.2s;
}

.full-width {
  column-span: all;
}

.audio-player {
  width: 100%;
  height: 400px;
  background-color: black;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}


.top, footer {
  padding: 5vw;
  display: grid;
  grid-template-columns: 1fr auto;

  div, nav {
    align-self: center;
  }
}

.image {
  width: 100%;
  height: 100%;
}

.one-column {
  display: grid;
  grid-template-columns: 1fr;
}

.two-column {
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }

}

.three-column {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 3vw;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }

}

.asym-two-column {
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  gap: 5vw;

  @media (max-width: 900px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr 90px;
  }

}

textarea, input {
  background-color: #25282F;
  border-radius: 4px;
  border: none;
  outline: none;
  color: white;
  font-size: 16px;
  font-weight: 100;
  padding: 10px;
  width: 100%;
}

textarea {
  min-height: 15vw;
}

canvas {
  width: 100%;
}

.page-top {
  display: grid;
  align-items: center;
  grid-template-columns: 3vw 3vw auto;
  gap:1vw;

  @media (max-width: 900px) {
    grid-template-columns: 5vw 5vw auto;
    gap:2vw;
  }

  @media (max-width: 600px) {
    grid-template-columns: 8vw 8vw auto 8vw;
  }

  img {
    width: 3vw;
    @media (max-width: 900px) {
      width: 5vw;
    }
  
    @media (max-width: 600px) {
      width: 8vw;
    }
  }
}

.top {
  img { 
    width: 5vw;
    @media (max-width: 900px) {
      width: 8vw;
    }
  
    @media (max-width: 600px) {
      width: 10vw;
    }
  }

}

.mobileNav {
  background-color: #25282F;
  position: fixed;
  width: 100%;
  height: 100%;
  top:0;
  left:100vw;
  bottom: 0;
  z-index: 1;
  pointer-events: none;
  transition: left 0.2s;
  &.show {
    pointer-events: all;
    left:0vw;
    transition: left 0.3s;
  }



  nav {
    display: grid;
    gap: 10vw;
    place-content: center;
    align-items: center;
    height: 100%;
  }

  nav a {
    text-align: center;
    margin: 0.2em 0.5em;
    font-size: 2em;
    display: inline;
    transition: 0.2s;
    margin-bottom: 4px;
    &.active {
      transition: 0.2s;
      color: rgb(210, 210, 210);
      border-bottom: none;
      margin-bottom: 4px;
    }
  }
  

}

.border {
  border: 2px solid rgba(121, 37, 255, 0.358);
}

.pad-bottom {
  margin-bottom: 5vw;
}

.linktree a {
  display: block;
  border: 2px solid rgba(255,255,255,0.2);
  border-radius: 8px;
  margin-bottom: 2em;
  padding: 1em;
  display: grid;
  grid-template-columns: 100px auto;
  gap: 3vw;
  align-items: center;

  .emoji {
    font-size: 3em;
    align-self: center;
    text-align: center;
  }

  h2, p {
    margin: 0;
  }
  h2 {
    margin-bottom: 0.25em;
  }


}